<template>
  <div>
    <div class="panel-title">
      {{ $t("customerSupport") }}
    </div>
    <el-card style="margin-top:20px">
      <template v-if="$settings.customSupport === true">
        <div v-if="supportHtml[lang]" v-html="supportHtml[lang]"></div>
        <div v-else v-html="supportHtml['ja']"></div>
      </template>
      <template v-else>
        <div class="manual-list">
          <div class="content">
            <div class="title">{{ $t("starpayQuestion") }}</div>
            <div class="con-title">
              {{ $t("starpayQuestionTitle") }}
            </div>

            <el-button class="confirm" type="primary" style="margin-top: 10px;" v-on:click="goHelp">
              {{ $t("starpayQeustionWindow") }}
            </el-button>
          </div>
        </div>
        <div class="manual-list">
          <div class="content">
            <div class="title">{{ $t("starpayCallSupport") }}</div>
            <div class="con-title">{{ $t("starpayCallInofTitle") }}</div>
            <div class="other-content">{{ $t("starpaySupportCallNo") }}</div>
          </div>
        </div>
        <div class="manual-list">
          <div class="content">
            <div class="title">{{ $t("printingPaperTitle") }}</div>
            <div class="con-title">{{ $t("printingPaperContent") }}</div>
            <div class="other-content">
              <a
                target="view_window"
                href="https://www.amazon.co.jp/gp/product/B00009996Y/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=netstars05-22&creative=1211&linkCode=as2&creativeASIN=B00009996Y&linkId=7497fdfef9d5aecc1d8bbe89ca62d116"
              >
                <img :src="printImg01" alt="" style="width:223px;" />
              </a>
              <a
                target="view_window"
                href="https://www.amazon.co.jp/gp/product/B00HIUXOC6/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=netstars05-22&creative=1211&linkCode=as2&creativeASIN=B00HIUXOC6&linkId=1a0da3bd365b3ca5dc78d961bf6d964e"
              >
                <img :src="printImg02" alt="" style="width:223px;" />
              </a>
            </div>
          </div>
        </div>
      </template>
    </el-card>
  </div>
</template>

<script>
import printImg01 from "@/assets/goods1.jpg";
import printImg02 from "@/assets/goods2.jpg";
export default {
  data() {
    return {
      printImg01,
      printImg02,
      supportHtml: {},
      lang: ""
    };
  },
  created: function() {
    if (this.$settings.customSupport === true) {
      console.log(this.$settings.supportHtml);
      console.log(JSON.parse(this.$settings.supportHtml));
      let str = this.$settings.supportHtml;
      this.supportHtml = JSON.parse(str);
      this.lang = this.$store.state.app.language;
    }
  },
  methods: {
    goHelp() {
      if (this.$settings.oem === "rm") {
        window.open("https://regionalmarketing.co.jp/lp/settlement/contact/");
      } else {
        window.open("https://www.netstars.co.jp/starpay-faq/");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.other-content {
  margin-top: 10px;

  a {
    color: #28262e;
    margin-right: 20px;
  }
}
</style>
